.document-link {
    color: black !important;
    text-decoration: none !important;
    padding-left: 15px;
    padding-right: 15px;
}

.document-button {
    color: white !important;
    margin-left: 10px;
    text-decoration: none !important;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}

.button-link {
    color: white !important;
    margin-right: 5px !important;
    display: block;
}



.button-link:hover {
    color: white !important;
}

.button-link:active {
    background: #dbdbdb33 !important;
}