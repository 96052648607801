.color-success {
    background: rgba(0, 128, 0, 0.2) !important;
}
.color-decline {
    background: rgba(255, 0, 0, 0.2) !important;
}

.color-wait_delete {
    background: rgba(253, 163, 62, 0.2) !important;
}

.MuiDataGrid-root .MuiDataGrid-cell{
    /*color: tomato;*/
    white-space: normal !important;
    word-wrap: break-word !important;
}


/*#permalink_section*/
/*{*/
/*    !*width:300px;*!*/
/*    !*height:400px;*!*/
/*    border:1px solid red;*/
/*    white-space: pre-wrap; !* css-3 *!*/
/*    white-space: -moz-pre-wrap; !* Mozilla, since 1999 *!*/
/*    white-space: -pre-wrap; !* Opera 4-6 *!*/
/*    white-space: -o-pre-wrap; !* Opera 7 *!*/
/*    word-wrap: break-word; !* Internet Explorer 5.5+ *!*/

/*}*/
