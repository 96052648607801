.color-payed {
    background: rgba(0, 128, 0, 0.2) !important;
}

.color-Canceled {
    background: rgba(255, 0, 0, 0.2) !important;
}

.color-OnChecking {
    background: rgba(255, 255, 0, 0.2) !important;
}

.MuiDataGrid-root .MuiDataGrid-cell{
    /*color: tomato;*/
    white-space: normal !important;
    word-wrap: break-word !important;
}

.MuiMultiInputDateTimeRangeField-root .MuiInputBase-input {
    height: 10px;
}
