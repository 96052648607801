.custom-tooltip {
    background: white;
}

.label {
    background: lightblue;
}

.MuiMultiInputDateTimeRangeField-root .MuiInputBase-input {
    height: 10px;
}